import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import FAQ from "components/faqs/TwoColumnPrimaryBackground";

export default () => {
  return (
    <AnimationRevealPage disabled>
      <Header />
      <FAQ />
      <Footer/>
    </AnimationRevealPage>
  );
};
